.flower-cards {
  .tile.is-child {
    border-radius: 24px;
    padding: 16px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    color: #4a4a4a;
    font-weight: 900;
    height: 240px;
  }
}

.footnote-nav {
  a {

    color: #fff;
  }
}