body {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.w-1200 {
  max-width: 1200px;
  margin: auto;
}

a {
  color: #000;
}

.is-size-1 {
  line-height: 1.1;
}

.is-size-2 {
  line-height: 1.1;
}

@media screen and (max-width: 1024px) {
    .container.is-fluid-custom {
      margin-right: 16px;
      margin-left: 16px;
    }
}
